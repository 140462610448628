import { FormattedMessage } from "react-intl";

import { SystemBoundary } from "../../__generated__/globalTypes";
import { useSystemBoundary } from "../../services/useOrganizationFeatures";
import assertNever from "../../util/assertNever";

export default function SystemBoundaryText() {
  const systemBoundary = useSystemBoundary();

  if (systemBoundary === SystemBoundary.CRADLE_TO_PROCESSING_GATE) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToProcessingGateSystemBoundary"
        defaultMessage="System Boundary: <strong>Cradle To Processing Gate</strong>"
        values={{
          strong: (chunks: React.ReactNode) => (
            <strong className="medium-font">{chunks}</strong>
          ),
        }}
      />
    );
  } else if (systemBoundary === SystemBoundary.CRADLE_TO_GRAVE) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToGraveSystemBoundary"
        defaultMessage="System Boundary: <strong>Cradle To Grave</strong>"
        values={{
          strong: (chunks: React.ReactNode) => (
            <strong className="medium-font">{chunks}</strong>
          ),
        }}
      />
    );
  } else if (systemBoundary === SystemBoundary.CRADLE_TO_RETAIL_DOORSTEP) {
    return (
      <FormattedMessage
        id="components/recipes/SystemBoundaryText:cradleToRetailDoorstepSystemBoundary"
        defaultMessage="System Boundary: <strong>Cradle To Retail Doorstep</strong>"
        values={{
          strong: (chunks: React.ReactNode) => (
            <strong className="medium-font">{chunks}</strong>
          ),
        }}
      />
    );
  } else {
    assertNever(systemBoundary, "Unsupported system boundary");
  }
}
